








































import {PlainObject} from '@labor-digital/helferlein';
import {forEach} from '@labor-digital/helferlein';
import {escapeRegex} from '@labor-digital/helferlein';
import {isString} from '@labor-digital/helferlein';
import Url from 'url-parse';
import {isBrowser} from '@labor-digital/helferlein';

export default {
    name: 'dx-link-wrap',
    props: {
        isNavItem: {
            type: Boolean,
            required: false
        },

        isNavBrand: {
            type: Boolean,
            required: false
        },

        isButton: {
            type: Boolean,
            default: false
        },

        centered: {
            type: Boolean,
            default: false
        },

        fullWidth: {
            type: Boolean,
            default: false
        },

        uppercase: {
            type: Boolean,
            default: true
        },

        rounded: {
            type: Boolean,
            default: true
        },

        outline: {
            type: Boolean,
            default: false
        },

        variant: {
            type: String,
            default: 'blue'
        },

        size: {
            type: String,
            default: 'default'
        },

        customClasses: {
            type: String,
            default: ''
        },

        /**
         * Additional html properties to add to the link
         */
        linkProps: {
            type: Object,
            default: () => {
                return {};
            }
        } as PlainObject,

        /**
         * Defines the forced type of the button to render
         * Values are:
         *  - "auto" automatically select the type based on the given link prop
         *  - "external" always open the link in a new window
         *  - "phone" always handle the link as a phone number
         *  - "js" emits a vue event "click" when the button was clicked, but does nothing else
         *  - "mail" executes a "mailto:" to the given "link"
         */
        type: {
            type: String,
            default: 'auto',
            validator(v)
            {
                return (
                    ['auto', 'external', 'phone', 'js', 'mail']
                ).indexOf(v) !== -1;
            }
        },

        /**
         * Defines which link tag should be generated
         */
        tag: {
            type: String,
            default: 'a'
        },

        /**
         * If set to true the button will not do anything
         */
        disabled: {
            type: Boolean,
            default: false
        },

        /**
         * The link to open when a user clicks on this link
         */
        link: {
            type: String,
            default: ''
        },

        /**
         * Allows you to provide a map of a phone number prefix "key" that should be converted into an
         * international prefix "value"
         */
        phoneIntlMap: {
            '0': '+49'
        },

        /**
         * Forces the link to always handle redirect the user to a new tab
         * @deprecated use "type": "external" instead!
         */
        alwaysExternal: {
            type: Boolean,
            default: false
        },

        /**
         * Render the link as disabled element
         * @deprecated use "disabled" instead
         */
        isDisabled: {
            type: Boolean,
            default: false
        },

        /**
         * If this is set to true the component will emit an event of type "click" (NOT NATIVE!)
         * when the link was clicked instead of following the link. The default action is prevented!
         * @deprecated use "type": "js" instead
         */
        emitOnClick: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        /**
         * Tires to find the correct type of the link if "auto" is selected as type
         */
        realType(): string
        {
            if (this.type !== 'auto') {
                return this.type;
            }

            // JS
            if (this.emitOnClick) {
                return 'js';
            }

            // NOOP
            if (!isString(this.link) || this.link === '' || this.isDisabled || this.disabled) {
                return 'noop';
            }

            // PHONE
            if (this.link.match(/[^0-9\s+\-/():]/) === null && this.link.length >= 8) {
                return 'phone';
            }

            // E-MAIL
            if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/.test(this.link)) {
                return 'mail';
            }

            // EXTERNAL
            const url = new Url(this.link);
            const baseUrl = new Url(this.$route.fullPath);
            if (url.host !== baseUrl.host || this.alwaysExternal) {
                return 'external';
            }

            if (url.hash && url.pathname === baseUrl.pathname)
            {
                // console.log('baseUrl', baseUrl)
                // console.log('url', url)
                return 'anchor';
            }

            // Fall back to router link
            return 'internal';
        },

        /**
         * Returns true if the vue router link should be used instead of the custom components
         */
        useVueRouter(): boolean
        {
            return this.realTag === this.tag && this.realType !== 'external' && this.realType !== 'anchor';
        },

        /**
         * Internal helper to translate the tag to the bootstrap components if they are switched by the props
         */
        realTag(): string
        {
            return this.tag;
        },

        /**
         * Processes the href attribute based on the type of the given link
         */
        href(): string
        {
            const url = new Url(this.link);

            switch (this.realType)
            {
                case 'mail':
                    return '';
                case 'phone':
                    let number = this.link;
                    forEach(this.phoneIntlMap, (v, k) => {
                        if (number.indexOf(k + '') === 0) {
                            number = number.replace(new RegExp('^' + k + ''), v);
                            return false;
                        }
                    });
                    return 'tel:' + number;
                case 'internal':
                    const pattern = new RegExp('^' + escapeRegex(url.protocol + '//' + url.host));
                    return this.link.replace(pattern, '');
                case 'anchor':

                    let hash = url.hash;
                    if (hash.includes('#c'))
                    {
                        hash = hash.replace('#c', '#content-element-');
                    }
                    return hash;

                case 'external':
                    if (this.link.match(/^https?:/) === null) {
                        return 'http://' + this.link;
                    }
                    return this.link;
                default:
                    return this.link;
            }
        },

        /**
         * The list of generated css classes for this link
         */
        classes(): PlainObject
        {
            let variant = this.variant;
            let variantHover = this.variant;
            if (variant === 'black')
            {
                variantHover = 'blue';
            }

            return {
                ['dx-link-wrap--' + this.realType]: true,
                ['uppercase']: this.uppercase,
                ['flex items-center justify-center']: this.centered,
                ['w-full']: this.fullWidth,
                ['w-auto md:w-auto text-center mx-auto']: !this.fullWidth,
                ['px-7 py-3 md:px-8 md:py-3 font-medium text-lg md:text-lg transition border border-2 text-'+variant+' hover:text-white border-'+variant+'-500 hover:bg-'+variant+'-500']: this.outline && this.isButton,
                ['px-7 py-3 md:px-8 md:py-3 font-medium text-lg md:text-lg transition border border-transparent text-white bg-'+variant+'-500 hover:bg-'+variant+'-600']: this.isButton && !this.outline,
                ['font-medium text-'+variant+'-500 hover:text-'+variantHover+'-600 transition']: !this.isButton,
                ['rounded-full']: this.rounded && this.isButton,
                // [variant]: true,
                [this.customClasses]: true,
            };
        },

        /**
         * The list of required event handlers on the button component
         */
        listeners(): PlainObject
        {
            switch (this.realType)
            {
                case 'noop':
                    return {
                        'click': this.onNoopClick
                    };
                case 'js':
                    return {
                        'click': this.onClick
                    };
                case 'anchor':
                    return {
                        'click': this.onClickAnchor
                    };
                case 'mail':
                    return {
                        'click': this.onMailClick
                    };
                default:
                    return {};
            }
        },

        /**
         * The list of required html attributes to add to the button node
         */
        attr(): PlainObject
        {
            const attr: PlainObject = {};

            if (this.realType === 'external') {
                attr.href = this.href;
                attr.target = '_blank';
                attr.rel = 'noopener,noreferrer';
            }
            else if(this.useVueRouter || this.realTag !== this.tag){
                attr.to = this.href;
            } else {
                attr.href = this.href;
            }

            return attr;
        },

    },
    methods: {
        scrollToTarget(target)
        {

        },
        onClick(e: MouseEvent)
        {
            e.preventDefault();
            this.$emit('click', e);
        },
        onClickAnchor(e: MouseEvent)
        {
            // console.log('onClickAnchor', e)
            e.preventDefault();
            const ele = document.getElementById(this.href.replace('#',''))

            if(ele)
            {
                // @ts-ignore
                ele.scrollIntoView({ left: 0, block: 'start', behavior: 'smooth' });
            }
        },
        onNoopClick(e: MouseEvent)
        {
            e.preventDefault();
        },
        onMailClick(e: MouseEvent)
        {
            e.preventDefault();
            if(isBrowser())
            {
                window.location.href = 'mailto:' + this.link;
            }
        }
    }
};
