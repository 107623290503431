/*
 * Copyright 2020 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2020.04.17 at 17:38
 */

/**
 * The list of keys that are available in the store for this application.
 */
export enum AppStoreKeys
{

    /**
     * Contains the current breakpoint based on the viewport width
     */
    BREAKPOINT = 'breakpoint',

    /**
     * The current scroll position from the top of the page
     */
    SCROLL_POS = 'scrollPos',

    /**
     * A global marker to show the page loader or not
     */
    APP_LOADER_SHOW = 'showAppLoader',

    /**
     * Used to temp store contactData to be used in ContactForm
     */
    CONTACT_DATA = 'contactData',

    PAGE_TRANSITION_NAME = 'pageTransitionName',

    PAGE_CHANGED = 'pageChanged',

    CURRENT_NAV_LEVEL = 'currentNavLevel',

    MENU_TOGGLED = 'menuToggled',

    /**
     * True if the branch search map should be rendered in select mode
     */
    UC_STORE = 'ucStore'
}
