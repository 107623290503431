var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "dx-app-container",
    {
      class: _vm.transitionName,
      attrs: { context: _vm.context, "sticky-footer": false },
    },
    [
      _c("dx-sidebar", { attrs: { sidebarLinks: _vm.sidebarLinks } }),
      _vm._v(" "),
      _c("dx-main-menu", { attrs: { context: _vm.context } }),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "main default-layout" },
        [
          _c("dx-navbar", {
            attrs: {
              context: _vm.context,
              "has-large-hero": _vm.hero.isLarge,
              "show-contact-btn": _vm.level > 1 && !_vm.isContactPage,
              sticky: true,
            },
          }),
          _vm._v(" "),
          _c("dx-hero", { attrs: { context: _vm.context, hero: _vm.hero } }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dx--main-container" },
            [
              _c("content-element-children", {
                attrs: { col: 0, context: _vm.context },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("dx-footer", { attrs: { context: _vm.context } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }