var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dx-main-menu fixed inset-0 overflow-hidden",
      class: { "dx-main-menu--hidden": !_vm.isOpen },
      attrs: {
        "aria-labelledby": "slide-over-title",
        role: "dialog",
        "aria-modal": "true",
      },
    },
    [
      _c("div", { staticClass: "absolute inset-0 overflow-hidden" }, [
        _c(
          "div",
          { staticClass: "absolute inset-0", attrs: { "aria-hidden": "true" } },
          [
            _c(
              "div",
              {
                staticClass:
                  "fixed inset-y-0 right-0 pl-8 md:pl-20 max-w-full flex",
              },
              [
                _c("div", { staticClass: "w-screen max-w-full md:max-w-2xl" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "h-full flex flex-col pl-4 md:pl-20 pr-0 md:pr-6 bg-blue-500 shadow-xl overflow-hidden",
                      staticStyle: { "padding-left": "1rem" },
                    },
                    [
                      _c("div", { staticClass: "px-4 sm:px-6" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex items-start justify-between mt-6",
                          },
                          [
                            _c("div", { staticClass: "flex items-center" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "bg-blue-500 hover:bg-blue-600 w-12 h-12 rounded-full transition",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.close()
                                    },
                                  },
                                },
                                [
                                  _c("span", { staticClass: "sr-only" }, [
                                    _vm._v("Close"),
                                  ]),
                                  _vm._v(" "),
                                  _c("dx-icon", {
                                    attrs: {
                                      type: "x",
                                      color: "white",
                                      size: "md",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mt-2 relative flex-1 px-4 sm:px-6" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "absolute inset-0 px-4 sm:px-6 flex flex-col justify-between overflow-y-scroll",
                              staticStyle: { height: "calc(100% - 75px)" },
                            },
                            _vm._l(_vm.mainMenu, function (item) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  staticClass: "flex flex-col items-start ml-3",
                                },
                                _vm._l(item.children, function (child, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "py-1 w-full" },
                                    [
                                      _c("DxMainMenuItem", {
                                        key: child.id,
                                        attrs: { item: child },
                                      }),
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "footer",
                        {
                          staticClass:
                            "footer dx-footer mb-0 absolute bg-blue-500",
                          staticStyle: {
                            bottom: "0",
                            "padding-bottom": "20px",
                            width: "90%",
                            "padding-left": "1rem",
                            "z-index": "1",
                            height: "75px",
                          },
                          attrs: { "aria-labelledby": "footer-heading" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "dx--container-wide2 dx-footer__container p-0 md:py-4 md:px-4 lg:py-4",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex flex-row items-center justify-between",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex flex-row md:flex-none",
                                    },
                                    _vm._l(
                                      _vm.socialLinks,
                                      function (socialLink, index) {
                                        return _c(
                                          "a",
                                          {
                                            key: index,
                                            staticClass:
                                              "hover:bg-blue-600 text-center p-2 rounded-full flex items-center",
                                            attrs: {
                                              href: socialLink.href,
                                              target: "_blank",
                                            },
                                          },
                                          [
                                            socialLink.title === "Facebook"
                                              ? _c("dx-icon", {
                                                  attrs: {
                                                    type: "facebook",
                                                    size: "xl",
                                                    color: "white",
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            socialLink.title === "Twitter"
                                              ? _c("dx-icon", {
                                                  attrs: {
                                                    type: "twitter",
                                                    size: "xl",
                                                    color: "white",
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            socialLink.title === "Xing"
                                              ? _c("dx-icon", {
                                                  attrs: {
                                                    type: "xing",
                                                    size: "xl",
                                                    color: "white",
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            socialLink.title === "LinkedIn"
                                              ? _c("dx-icon", {
                                                  attrs: {
                                                    type: "linkedIn",
                                                    size: "xl",
                                                    color: "white",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass:
                                      "dx-footer__line w-full mx-7 flex",
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }