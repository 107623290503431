var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dx-image",
      class: {
        "animate__animated animate__fadeIn animate__fast": _vm.animate,
        "animate__delay-05s": _vm.animate,
      },
    },
    [
      _vm.attrImage && _vm.attrImage.url
        ? _c("dx-picture-tag", {
            staticClass: "w-full",
            attrs: {
              alt: _vm.attrImage.alt,
              desc: _vm.attrImage.desc,
              src: _vm.attrImage.url,
              srcLg: _vm.attrImage.urlLg,
              srcSm: _vm.attrImage.urlSm,
              title: _vm.attrImage.title,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }