
import "core-js/features/promise/index.js";
import "core-js/features/set/index.js";
import "core-js/features/map/index.js";
import "core-js/features/object/assign.js";
import "core-js/features/object/entries.js";
import "core-js/features/object/keys.js";
import "core-js/features/array/from.js";
import "core-js/features/symbol/index.js";
import "core-js/features/array/includes.js";
import {
    BreakpointService,
    configureScrollToTopOf,
    getScrollPos,
    HelferleinEventList,
    isBrowser,
    isFunction,
    isUndefined,
    registerEventBreakPointChange,
    registerEventResizeThrottled,
    registerEventScrollThrottled,
    scrollToTopOf,
    stopBodyScrolling,
    ucFirst
} from '@labor-digital/helferlein';
import {AppContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/AppContext';
import {FrameworkEventList} from '@labor-digital/typo3-vue-framework/lib/Core/Interface/FrameworkEventList';
import {SpaApp} from '@labor-digital/typo3-vue-framework/lib/Variants/SpaApp';

// - add needed bootstrap components
import Vue from 'vue';

// - add swiper
import 'swiper/swiper-bundle.css';
// import "swiper/css/pagination"
import VueAwesomeSwiper from 'vue-awesome-swiper';
// - add app styling
import './Style/tailwind.css';
import './AppV2.scss';

import {AppStoreKeys} from './AppStoreKeys';
import DefaultLayout from './Layout/Default/DefaultLayout.vue';

// - services
import {UcService} from './Service/UcService';
import {MenuService} from './Service/MenuService';
import {JsonLdService} from './Service/JsonLdService';
import {EtrackerService} from './Service/EtrackerService';
import {MailService} from './Service/MailService';
import {GoogleTagManagerService} from './Service/GoogleTagManagerService';
// import {FbPixelService} from './Service/FbPixelService';
// @ts-ignore
import VueAnimateOnScroll from 'Directive/AnimateOnScroll/AnimateOnScroll.directive';
import {SpaAppConfigInterface} from '@labor-digital/typo3-vue-framework/lib/Core/Config/SpaAppConfigInterface';

Vue.use(VueAwesomeSwiper);

// @ts-ignore
Vue.use(VueAnimateOnScroll);

// - setup app
const config: SpaAppConfigInterface = {
    api: {
        baseUrl: ''
    },
    additionalConfiguration: (environment, config) => {
        // Use the app domain as base url
        config.api.baseUrl = environment.envVars.APP_PROTOCOL + environment.envVars.APP_DOMAIN;

        // Special SSR Settings
        if (config.vue.vueEnvironment === 'server') {
            // Check if we are in dev mode -> docker compose url resolution
            // @todo change this for the production environment
            if (environment.type === 'development') {
                config.api.baseUrl = 'https://' + environment.envVars.COMPOSE_PROJECT_NAME + '-typo';
            }
        }
    },
    vue: {
        dynamicComponentResolver: function (type: string) {
            // console.log('dynamicComponentResolver', type);
            const parts = type.split('/');
            if (parts.length === 4) {
                // console.log('./Component/ContentElement/' + ucFirst(parts[0]) + '/' + ucFirst(parts[1]) + '/' + ucFirst(parts[2]) + '/' + ucFirst(parts[3]) + '.vue');
                return import('./Component/ContentElement/' + ucFirst(parts[0]) + '/' + ucFirst(parts[1]) + '/' + ucFirst(parts[2]) + '/' + ucFirst(parts[3]) + '.vue');
            } else if (parts.length === 3) {
                // console.log('./Component/ContentElement/' + ucFirst(parts[0]) + '/' + ucFirst(parts[1]) + '/' + ucFirst(parts[2]) + '/' + ucFirst(parts[2]) + '.vue');
                return import('./Component/ContentElement/' + ucFirst(parts[0]) + '/' + ucFirst(parts[1]) + '/' + ucFirst(parts[2]) + '/' + ucFirst(parts[2]) + '.vue');
            } else {
                // console.log('./Component/ContentElement/' + ucFirst(parts[0]) + '/' + ucFirst(parts[1]) + '/' + ucFirst(parts[1]) + '.vue');
                return import('./Component/ContentElement/' + ucFirst(parts[0]) + '/' + ucFirst(parts[1]) + '/' + ucFirst(parts[1]) + '.vue');
            }
        },
        layoutComponents: {
            1: DefaultLayout,
            default: DefaultLayout
        },
    },
    errorHandling: {
        routes: [
            {
                route: '/404',
                code: 404
            }
        ]
    },
    staticMeta: {
        titleTemplate: '%s | dialocx',
        script: [
            {
                vmid: 'usercentrics',
                id: 'usercentrics-cmp',
                async: true,
                src: 'https://app.usercentrics.eu/browser-ui/latest/loader.js',
                'data-version': 'preview',
                'data-settings-id': 'E-0XhhdHDVFRie'
            },
            {
                vmid: 'facebookPixel',
                type: 'text/plain',
                charset: 'utf-8',
                innerHTML: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '1120480028708214');
                fbq('track', 'PageView');
`,
                'data-usercentrics': 'Facebook Pixel',
                'data-type': 'application/javascript',
                'data-name': 'facebookPixel'
            },
            {
                vmid: 'etracker',
                type: 'text/plain',
                'data-usercentrics': 'etracker',
                charset: 'utf-8',
                id: '_etLoader',
                defer: true,
                body: true,
                src: '//static.etracker.com/code/e.js',
                'data-block-cookies': 'true',
                'data-respect-dnt': 'true',
                'data-secure-code': 'N8KAME'
            }
        ],
        meta: [
            {property: 'og:site_name', content: 'Dialocx'},
            {name: 'robots', content: 'index,follow'}
        ],
        __dangerouslyDisableSanitizersByTagID: {
            'gta': ['innerHTML'],
            'facebookPixel': ['innerHTML']
        }
    },
    events: {
        [FrameworkEventList.HOOK_INIT]: function (e, context: AppContext) {
            // Initialize breakpoint
            context.store.set(AppStoreKeys.BREAKPOINT, BreakpointService.getCurrent() ?? {is: () => false});

            // Initialize services
            MenuService.initialize(context);
            MailService.initialize(context);
            JsonLdService.initialize(context);
        },
        [FrameworkEventList.HOOK_INIT_BROWSER]: function (e, context: AppContext) {
            // Initialize etracker
            EtrackerService.initialize();

            // Initialize ucentrics
            UcService.init(context);

            // Register handler to provide the scroll position to the components
            registerEventScrollThrottled();

            // Register handler to provide the current breakpoint
            registerEventBreakPointChange();

            // Register handler to provide a throttled resize event
            registerEventResizeThrottled();

            // Configure scroll to top offset
            configureScrollToTopOf({
                breakOnManualScroll: true,
                offset: 150
            });
        },
        [FrameworkEventList.EVENT_ROUTE_BEFORE_NAVIGATION]: function (e, context: AppContext) {
            if (isBrowser()) {
                // Scroll up if we are doing a navigation
                stopBodyScrolling(false);

                // Register handler to show page loader if the page-request takes to long
                // const loaderTimeout = setTimeout(() => {
                //     context.store.set(AppStoreKeys.APP_LOADER_SHOW, true);
                // }, 300);

                const afterNavigationHandler = function () {
                    context.eventEmitter.unbind(FrameworkEventList.EVENT_ROUTE_AFTER_NAVIGATION, afterNavigationHandler);
                    context.store.set(AppStoreKeys.APP_LOADER_SHOW, false);

                    // - scroll top after page change
                    scrollToTopOf(null, {duration: 0});

                    // console.log('transitionName from', e.args.from);
                    // console.log('transitionName to', e.args.to);

                    const to = e.args.to;
                    const from = e.args.from;
                    const toDepth = to.path.split('/').length;
                    const fromDepth = from.path.split('/').length;

                    const transitionName = toDepth < fromDepth ? 'fadeIn' : 'fadeIn';
                    // const transitionName = toDepth < fromDepth ? 'slideInRight' : 'slideInLeft'

                    let level;
                    if (to.path === '/') {
                        level = 0;
                    } else {
                        level = toDepth - 1;
                    }

                    context.store.set(AppStoreKeys.PAGE_TRANSITION_NAME, transitionName);
                    context.store.set(AppStoreKeys.PAGE_CHANGED, 'true');
                    context.store.set(AppStoreKeys.CURRENT_NAV_LEVEL, level);

                    // close menu on page change
                    context.store.set(AppStoreKeys.MENU_TOGGLED, false);

                    // @ts-ignore
                    // window.dataLayer.push({event: 'page_changed'});

                    // clearTimeout(loaderTimeout);
                };
                context.eventEmitter.bind(FrameworkEventList.EVENT_ROUTE_AFTER_NAVIGATION, afterNavigationHandler);
            }
        },
        [FrameworkEventList.EVENT_ROUTE_AFTER_NAVIGATION]: function (e, context: AppContext) {

            // Hide the loader
            context.store.set(AppStoreKeys.APP_LOADER_SHOW, false);

            // Track the navigation
            // @ts-ignore
            if (context.env !== 'development' && isBrowser() && !isUndefined(window.dataLayer) && isFunction(window.dataLayer)) {
                setTimeout(() => {
                    // @ts-ignore
                    window.dataLayer.push({event: 'pageview'});
                }, 150);

                // Trigger the tracking event
                EtrackerService.trackPageChange();
                EtrackerService.initializeOptOutSnippets();

                // Track the navigation
                GoogleTagManagerService.trackPageChange();
            }

            const to = e.args.to;

            // - if there is a hash on this page, scroll to it
            if (to.hash) {
                const t = setTimeout(() => {
                    let hash = to.hash;
                    if (hash.includes('#c') && !hash.includes('#content-element-')) {
                        hash = hash.replace('#c', '#content-element-');
                    }

                    // console.log('[App] hash after', hash)

                    const ele = document.getElementById(hash.replace('#', ''));
                    if (ele) {
                        // @ts-ignore
                        window.ele = ele;

                        // console.log('[App] ele after', ele)
                        ele.scrollIntoView();
                    }
                    clearTimeout(t);
                }, 200);
            }
        },
        [HelferleinEventList.EVENT_SCROLL_THROTTLED]: function (e, context: AppContext) {
            context.store.set(AppStoreKeys.SCROLL_POS, getScrollPos());
        },
        [HelferleinEventList.EVENT_BREAKPOINT_CHANGE]: function (e, context: AppContext) {
            context.store.set(AppStoreKeys.BREAKPOINT, BreakpointService.getCurrent());
        }
    }
};
export default SpaApp.init(config);

