/*
 * Copyright 2021 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2021.01.11 at 10:21
 */

import {getData, isBrowser as testIsInBrowser, isFunction, isUndefined, PlainObject, isEmpty} from '@labor-digital/helferlein';

declare global
{
    interface Window
    {
        _etracker?: PlainObject,
        et_ClickEvent?: any
        et_eC_Wrapper?: any
        _bt?: any
        _btNoWs?: any
        _dcLaunch?: any,
        UC_UI?: PlainObject
    }
}

let accountCode = null;
let isInitialPageChange = true;
let isOptimiseInitialized = false;
const isBrowser = testIsInBrowser();

export class EtrackerService
{
    /**
     * Initializes the service by registering event handlers
     */
    public static initialize(): void
    {
        EtrackerService.initializeOptimize();
    }

    // Register a watcher to forcefully reinitialize the optimize service
    public static watch(): void
    {
        if (!isEmpty(window.UC_UI)) {
            const services: Array<PlainObject> = window.UC_UI.getServicesBaseInfo();
            const service = services.filter((service) => service.name === 'etracker');

            if (isEmpty(service[0])) {
                console.log('ERROR: Service etracker does not exist!');
                return;
            }

            //
            EtrackerService.initializeOptimize();
        }
    }

    /**
     * Initializes the opt-out buttons on the current page
     * Searches and replaces all A tags like: <a data-tld="yourdomain.com" data-ignore-cms-link="true" href="#" id="et-opt-out">Etracker Opt-Out</a>
     * from the source code and initializes the cookieless-opt out snippet on them
     */
    public static initializeOptOutSnippets(): void
    {
        if (isBrowser && !isUndefined(window._etracker)) {
            setTimeout(function () {
                const tags = document.querySelectorAll('#et-opt-out');
                for (let i = 0; i < tags.length; i++) {
                    window._etracker.initOptOutButtons();
                }
            }, 1000);
        }
    }

    /**
     * Helper to force etracker to reinitialize the optimiser functionality,
     * including the "smart messages"
     */
    public static initializeOptimize(): void
    {
        if (isBrowser && isFunction(window._dcLaunch)) {
            window._bt = undefined;
            window._btNoWs = undefined;
            window._dcLaunch(window);
        }
    }

    /**
     * Uses the dynamic content wrapper of etracker to emit an event when the page was changed.
     * @see https://www.etracker.com/docs/integration-setup/tracking-code-sdks/tracking-code-integration/dynamische-inhalte-wrapper/
     */
    public static trackPageChange(): void
    {
        // The first page change is already tracked by etracker -> so we have to ignore it here
        if (isInitialPageChange) {
            isInitialPageChange = false;
            return;
        }

        if (isBrowser && !isUndefined(window.et_eC_Wrapper)) {
            window.et_eC_Wrapper(this.getAccountCode());
        }
    }

    /**
     * Sends a click event to etracker, if it is loaded and initialized correctly
     * @param message
     */
    public static trackClick(message?: string): void
    {
        if (isBrowser && !isUndefined(window.et_ClickEvent)) {
            this.emit(new window.et_ClickEvent(message));
        }
    }

    /**
     * Helper to send an event to etracker if it is initialized
     * @param event
     * @protected
     */
    protected static emit(event): void
    {
        if (isBrowser && !isUndefined(window._etracker)) {
            window._etracker.sendEvent(event);
        }
    }

    /**
     * Internal helper to resolve the account code from the etracker loader script tag
     * @protected
     */
    protected static getAccountCode(): string | null
    {
        if (isBrowser && accountCode === null) {
            const el = document.getElementById('_etLoader');
            if (el) {
                accountCode = getData(el, 'secure-code', null);
            }
        }

        return accountCode;
    }
}
