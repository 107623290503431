









import {Component, Prop, Vue} from 'vue-property-decorator';
import {IAsset} from '../Asset.model';
import FsnBtn from '../../Btn/Btn.vue';
import {isEmpty, isPlainObject, isUndefined, PlainObject} from '@labor-digital/helferlein';
import DxPictureTag from 'src/Component/Common/Assets/pictureTag/PictureTag.vue';

@Component({
    name: 'dx-image',
    components: {DxPictureTag, FsnBtn},
})
export default class Image extends Vue {
    @Prop() readonly image: IAsset;
    @Prop() readonly definition: 'default' | 'sm' | 'lg';
    @Prop() readonly crop: string;
    @Prop({default: true}) readonly animate: boolean;
    @Prop({default: true}) readonly animateDelay: boolean;

    get attrImage() {
        if (!isEmpty(this.attr)) return {};

        let args: PlainObject = {};

        args = this.setupImage(this.image.url, args, this.definition);
        if (this.image && this.image.image) {
            args.urlSm = this.buildUrl(this.image.image, 'sm');
            args.urlLg = this.buildUrl(this.image.image, 'lg');
        }

        return args;
    }

    get attr() {
        if (!isPlainObject(this.image))
            return {class: {'imageTag--missing': true}};
        return {};
    }

    setupImage(url: string, args, definition = 'default') {
        let image: any = this.image;
        if (this.image && this.image.image) {
            image = this.image.image;
        }

        // console.log('image',image)
        if (image) {
            image.url = url;

            url = this.buildUrl(image, definition);

            args = {
                style: {
                    backgroundImage: 'url(' + url + ')'
                },
                class: 'imageTag',
                'data-size': JSON.stringify({width: image.width, height: image.height})
            };

            if (image.title !== null) {
                args.title = image.title;
                args.ariaLabel = image.title;
            }

            if (image.alt !== null) {
                args.alt = image.alt;
                if (isUndefined(args.ariaLabel)) args.ariaLabel = image.alt;
            }

            if (image.desc !== null) {
                args.desc = image.desc;
            }

            args.url = url;
        }


        return args;
    }

    /**
     * Builds the url for a single image with the given definition key
     */
    buildUrl(image, definition: string = 'default'): string {
        let url = image.url;

        if (image.isStatic === true) {
            return url;
        }

        if (definition !== '') {
            url += '&definition=' + definition;
            if (definition === 'lg') {
                url += '&isX2=true';
            }
        }
        if (this.crop && this.crop !== '') {
            url += '&crop=' + this.crop;
        }

        return url;
    }

}
