














import { Component, Prop, Vue } from "vue-property-decorator";
import DxLinkWrap from "../LinkWrap/LinkWrap.vue";

@Component({
    name: "dx-sidebar",
    components: { DxLinkWrap },
})
export default class Sidebar extends Vue {
    @Prop() sidebarLinks: any[];
}
