




















import {isBrowser} from '@labor-digital/helferlein';
import {PlainObject} from '@labor-digital/helferlein';

export default {
    name: 'dx-picture-tag',
    props: {
        src: {
            type: String,
            required: true
        },
        srcLg: {
            type: String,
            required: false
        },
        srcSm: {
            type: String,
            required: false
        },
        alt: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        desc: {
            type: String,
            default: ''
        }
    },
    computed: {
        supportsPicture(): boolean
        {
            return !isBrowser() || !!(
                window as any
            ).HTMLPictureElement;
        },
        sourceList(): Array<PlainObject>
        {
            const srcset = [
                {
                    srcset: this.src + ' 1x',
                    sizes: this.srcSm ? '780vw' : '100vw'
                }
            ];

            if (this.srcLg)
            {
                srcset.push(
                    {
                        srcset: this.srcLg + ' 2x',
                        sizes: '1440w'
                    }
                );
            }

            if (this.srcSm)
            {
                srcset.push(
                    {
                        srcset: this.srcSm,
                        sizes: '100w'
                    }
                );
            }

            return srcset;
        },
        ariaLabel()
        {
            return this.title === '' ? this.alt : this.title;
        },
        fallbackAttr()
        {
            return {
                style: {
                    backgroundImage: 'url(' + this.src + ')'
                },
                class: 'dx-picture-tag',
                alt: this.alt,
                title: this.title,
                ariaLabel: this.ariaLabel
            };
        }
    }
};
