






export default {
    name: 'dx-loader-spinner'
};
