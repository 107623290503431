import {PlainObject} from '@labor-digital/helferlein';
import TextMixin from './Text.mixin';
import {Component, Vue, Mixins} from 'vue-property-decorator';

@Component
// @ts-ignore
export default class OpenGraphMixin extends Mixins(TextMixin)
{
    get metaTags(): Array<PlainObject>
    {
        return [
            {'property': 'og:type', 'content': 'website', 'vmid': 'og:type'},
            {'property': 'og:image', 'content': this.ogImage, 'vmid': 'og:image'},
            {'property': 'og:title', 'content': this.ogTitle, 'vmid': 'og:title'},
            {'property': 'title', 'content': this.ogTitle, 'vmid': 'title'},
            {'property': 'description', 'content': this.ogDescription, 'vmid': 'description'},
            {'property': 'author', 'content': this.ogAuthor, 'vmid': 'author'},
            {'property': 'og:description', 'content': this.ogDescription, 'vmid': 'og:description'}
        ];
    };

    get ogTitle(): string
    {
        return this.getOgTitle();
    };

    getOgTitle(text?): string
    {
        return this.truncate(text, 95);
    };

    get ogDescription(): string
    {
        return this.getOgDescription();
    };

    getOgDescription(text?): string
    {
        return this.truncate(this.stripTags(text), 297);
    };

    get ogImage(): string
    {
        return this.getOgDescription();
    };

    getOgImage(image?): string
    {
        if (!image){
            return '';
        }
        return image.url;
    }

    get ogAuthor(): string
    {
        return this.getOgAuthor();
    };

    getOgAuthor(text?): string
    {
        return text ? text : 'Dialocx';
    };

    getMetaInfo()
    {
        return {
            title: this.ogTitle,
            meta: this.metaTags
        };
    }

}

