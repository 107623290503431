var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.sidebarLinks
    ? _c("div", { staticClass: "dx-sidebar" }, [
        _c(
          "ul",
          _vm._l(_vm.sidebarLinks, function (sidebarLink) {
            return _c(
              "li",
              [
                _c("dx-link-wrap", { attrs: { link: sidebarLink.link } }, [
                  _c("img", {
                    attrs: {
                      alt: sidebarLink.title,
                      src: "/" + sidebarLink.fields.icon,
                    },
                  }),
                ]),
              ],
              1
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }