var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasImage
    ? _c(
        "div",
        {
          staticClass: "dx-hero bg-green-500",
          class: { "dx-hero--lg": _vm.hero.isLarge },
        },
        [
          _vm.hero.dropTitle && _vm.hero.dropTitle.length
            ? _c("dx-drop", {
                attrs: {
                  links: _vm.hero.links,
                  text: _vm.hero.dropTitle,
                  type: "drop_2-black",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.hero.useVideo
            ? _c("dx-image", {
                staticClass: "w-full dx-hero__img",
                class: { "hidden lg:flex": _vm.hero.imageSm },
                attrs: { image: _vm.hero.image, definition: "lg" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.hero.imageSm
            ? _c("dx-image", {
                staticClass: "lg:hidden w-full dx-hero__img",
                attrs: { image: _vm.hero.imageSm, definition: "lg" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.hero.useVideo && _vm.hero.video.poster
            ? _c("div", { staticClass: "dx-hero__video" }, [
                _c(
                  "video",
                  {
                    staticClass: "w-full",
                    attrs: {
                      id: "dx-hero--video",
                      poster: _vm.hero.video.poster.url,
                      autoplay: "",
                      loop: "",
                      muted: "",
                    },
                    domProps: { muted: true },
                  },
                  [
                    _c("source", {
                      staticClass: "w-full",
                      attrs: {
                        src: _vm.hero.video.file.url,
                        type: _vm.hero.video.file.mime,
                      },
                    }),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hero.title && _vm.hero.title.length
            ? _c("div", { staticClass: "dx--container-wide relative" }, [
                _c(
                  "div",
                  {
                    staticClass: "dx-hero__headline",
                    class: {
                      "dx-hero__headline--sm": _vm.hero.displaySmallTitle,
                      "dx-hero__headline--lg": !_vm.hero.displaySmallTitle,
                    },
                  },
                  [
                    _c(
                      "h1",
                      {
                        staticClass:
                          "text-3xl md:text-4xl tracking-tight leading-tight font-extrabold text-black-500",
                        class: {
                          "lg:text-5xl xl:text-5xl": _vm.hero.displaySmallTitle,
                          "lg:text-5xl xl:text-6xl":
                            !_vm.hero.displaySmallTitle,
                        },
                      },
                      [
                        _c("span", {
                          staticClass: "block xl:inline",
                          domProps: { innerHTML: _vm._s(_vm.hero.title) },
                        }),
                      ]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }