






















import {PageContext} from "@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext";
import {Component, Prop, VModel, Vue} from "vue-property-decorator";
import DxIcon from "../Icon/Icon.vue";
import {PlainObject} from "@labor-digital/helferlein";
import {ContentElementComponentDefinitionInterface} from "@labor-digital/typo3-vue-framework/lib/Core/Interface/ContentElementComponentDefinitionInterface";

@Component({
    name: "dx-modal",
    components: {DxIcon}
})
export default class Modal extends Vue
{
    modalShow: Boolean = false;

    @Prop() readonly context: PageContext;
    @Prop() readonly contentElement: ContentElementComponentDefinitionInterface;
    @Prop() readonly contactData: PlainObject;
    @Prop({default: "contact"}) readonly modalId: string;
    @Prop({default: "xl"}) readonly size: string;
    @VModel({type: Boolean}) internalValue!: boolean;

    mounted()
    {
        if (this.contentElement.componentType === "Dialocx/Contact" && this.contactData)
        {
            this.contentElement.data.contactData = this.contactData;
            this.contentElement.data.contactData.modalId = this.modalId;
        }

        // console.log('[Modal] this.contentElement', this.contentElement.data.contactData);
    }
}
