



















import {scrollToTopOf} from "@labor-digital/helferlein";
import {PageContext} from "@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext";
import DxIcon from "../Icon/Icon.vue";
import DxBtn from "../Btn/Btn.vue";

import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import DxLoaderOverlay from "../LoaderOverlay/LoaderOverlay.vue";
import {AppStoreKeys} from "../../../AppStoreKeys";
import {isBrowser} from "@labor-digital/helferlein";
import {Meta} from '../../../Decorators/Meta.decorator';
import {JsonLdService} from '../../../Service/JsonLdService';
import OpenGraphMixin from 'src/Mixin/OpenGraph.mixin';

@Component({
    name: "dx-app-container",
    components: {DxLoaderOverlay, DxBtn, DxIcon}
})
export default class AppContainer extends Mixins(OpenGraphMixin)
{
    @Prop() readonly context: PageContext;
    @Prop({default: true}) stickyFooter: boolean;

    @Meta
    metaInfo()
    {
        return {
            script: [
                JsonLdService.makeOrganization(),
                JsonLdService.makeBreadcrumb(),
            ]
        };
    }

    mounted()
    {
        if (isBrowser())
        {
            this.$root.$on("bv::collapse::state", (collapseId: string, isJustShown: boolean) => {
                // console.log('[AppContainer] collapse collapseId', collapseId)
                if (collapseId === 'main-menu')
                {
                    if (isJustShown) {
                        document.getElementById("app-container").classList.add("main-menu--open");
                    } else {
                        document.getElementById("app-container").classList.remove("main-menu--open");
                    }
                }
            })
        }
    }

    get classes() {
        return {
            ['app-container--sticky-footer']: this.stickyFooter
        };
    }

    get upOpen(): boolean
    {
        return this.context.store.get(AppStoreKeys.SCROLL_POS, 0) > 200;
    }

    get showPageLoader(): boolean
    {
        if (!isBrowser()) return false;

        return this.context.store.get(AppStoreKeys.APP_LOADER_SHOW, false);
    }

    onUpClick()
    {
        scrollToTopOf();
    }

    get header()
    {
        const header = this.context ? this.context.data.get('header') : null;
        return header ? header : {};
    }

    get ogTitle(): string
    {
        return this.getOgTitle(this.header.title);
    };

    get ogDescription(): string
    {
        return this.getOgDescription(this.header.text);
    };

};
