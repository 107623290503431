








































import DxLinkWrap from "../../LinkWrap/LinkWrap.vue";
import DxTextBlock from "../../TextBlock/TextBlock";
import { Component, Prop, Vue, Emit } from "vue-property-decorator";

// @ts-ignore
@Component({
    name: "dx-navbar-item",
    components: { DxLinkWrap, DxTextBlock },
})
export default class NavbarItem extends Vue {
    @Prop() item: any;

    opened: boolean = false;

    @Emit()
    toggle() {
        this.opened = !this.opened;
    }

    @Emit()
    open() {
        this.opened = true;
    }

    @Emit()
    close() {
        this.opened = false;
    }
}
