var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dx-video dx-video-player", attrs: { id: _vm.videoId } },
    [
      _vm.video && _vm.showPlayBtn
        ? _c(
            "div",
            {
              staticClass:
                "video-play-button d-flex flex-column align-items-center justify-content-center",
            },
            [
              _vm.displayPlayBtn
                ? _c(
                    "b-button",
                    {
                      staticClass: "play-button",
                      attrs: {
                        variant: "outline-primary",
                        pill: true,
                        size: "md",
                      },
                      on: { click: _vm.playVideo },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-between",
                        },
                        [
                          _c("span", { staticClass: "text-uppercase" }, [
                            _vm._v("Play Video"),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.displayPlayBtn
                ? _c(
                    "b-button",
                    {
                      staticClass: "stop-button",
                      attrs: {
                        variant: "outline-primary",
                        pill: true,
                        size: "md",
                      },
                      on: { click: _vm.stopVideo },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-between",
                        },
                        [
                          _c("span", { staticClass: "text-uppercase" }, [
                            _vm._v("Stop Video"),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.video && _vm.video.video && _vm.video.url
        ? _c(
            "b-embed",
            {
              attrs: {
                id: _vm.videoIdPlayer,
                type: _vm.type,
                aspect: _vm.aspect,
                poster: _vm.poster.url,
                allowfullscreen: "",
                muted: "",
              },
            },
            [
              !_vm.video.video.isYouTube && !_vm.video.video.isVimeo
                ? _c("source", {
                    attrs: { src: _vm.video.url, type: _vm.video.mime },
                  })
                : _c("source", { attrs: { src: _vm.video.url } }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.video && !_vm.video.video && _vm.video.url
        ? _c(
            "b-embed",
            {
              attrs: {
                id: _vm.videoIdPlayer,
                type: _vm.type,
                aspect: _vm.aspect,
                poster: _vm.poster.url,
                allowfullscreen: "",
                muted: "",
              },
            },
            [_c("source", { attrs: { src: _vm.video.url } })]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }