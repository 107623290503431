var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dx-link-wrap" },
    [
      !_vm.useVueRouter
        ? _c(
            _vm.realTag,
            _vm._g(
              _vm._b(
                {
                  tag: "component",
                  staticClass: "dx-link-wrap",
                  class: _vm.classes,
                },
                "component",
                _vm.attr,
                false
              ),
              _vm.listeners
            ),
            [_vm._t("default")],
            2
          )
        : _c(
            "router-link",
            _vm._g(
              _vm._b(
                {
                  staticClass: "dx-link-wrap",
                  class: _vm.classes,
                  attrs: { tag: _vm.tag },
                },
                "router-link",
                _vm.attr,
                false
              ),
              _vm.listeners
            ),
            [_vm._t("default")],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }