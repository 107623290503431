



























import {PageContext} from "@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext";
import DxIcon from "../Icon/Icon.vue";
import DxLinkWrap from "../LinkWrap/LinkWrap.vue";
import DxTextBlock from "../TextBlock/TextBlock.vue";
import { Component, Prop, Vue } from 'vue-property-decorator';

// @ts-ignore
@Component({
    name: 'dx-footer',
    components: {DxLinkWrap, DxTextBlock, DxIcon},
})
export default class Footer extends Vue
{
    @Prop() readonly context: PageContext;

    get classes()
    {
        return '';
    }

    get footerMenu()
    {
        return this.context.commonElements.footerMenu;
    }

    get contactLink()
    {
        return this.context.linkRepository.get('contact');
    }

    isLast(index: number)
    {
       return index === (Object.keys(this.footerMenu).length - 1);
    }

    isNotLast(index: number)
    {
       return index !== (Object.keys(this.footerMenu).length - 1);
    }
};
