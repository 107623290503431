var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dx-app-container app-container",
      class: _vm.classes,
      attrs: { id: "app-container" },
    },
    [
      _c(
        "dx-loader-overlay",
        { attrs: { show: _vm.showPageLoader } },
        [
          _vm._t("default"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "app-container__up",
              class: { "app-container__up--open transition": _vm.upOpen },
              on: { click: _vm.onUpClick },
            },
            [
              _c(
                "a",
                {
                  staticClass:
                    "px-6 py-3 rounded-full text-sm font-medium text-white hover:bg-blue-600 bg-blue-500 transition uppercase",
                  attrs: { href: "#" },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-between",
                    },
                    [
                      _c("span", { staticClass: "text-uppercase" }, [
                        _vm._v("Nach oben"),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }