


















































import { Component, Prop, Vue } from 'vue-property-decorator';
import {IAsset} from "../Asset.model";
import FsnBtn from "../../Btn/Btn.vue";
// import VueCoreVideoPlayer from 'vue-core-video-player';


@Component({
    name: 'dx-video-player',
    components: {FsnBtn},
})
export default class VideoPlayer extends Vue
{
    @Prop() readonly video: IAsset;
    @Prop() readonly poster: IAsset;
    @Prop() readonly backdrop: IAsset;
    @Prop({default: true}) showPlayBtn: boolean;

    /**
     * Type of embed. Possible values are 'iframe', 'video', 'embed' and 'object'
     **/
    @Prop({default: 'video'}) type: string;

    /**
     * Aspect ratio of the embed. Supported values are '16by9', '21by9', '4by3', and '1by1' and are translated to CSS classes.
     */
    @Prop({default: '16by9'}) aspect: string;

    _video: any;
    _wrapper: any;
    displayPlayBtn: boolean;

    created()
    {
        this.displayPlayBtn = true;

        //console.log('[VideoPlayer] video', this.video);
        //console.log('[VideoPlayer] poster', this.poster);
        //console.log('[VideoPlayer] backdrop', this.backdrop);
    };

    playVideo()
    {
        this._wrapper = document.getElementById('video-' + this.video.id);

        if (this._video)
        {
            this.stopVideo();
        }
        else{
            this._video = document.getElementById('video-player-' + this.video.id);
            this._video.play();

            if (this._wrapper){
                this._wrapper.classList.add('playing');
            }

            this.displayPlayBtn = false;
            this.toggleControls();
        }
    }

    stopVideo()
    {
        if (this._video)
        {
            this._video.pause();
            this._video = null;
        }

        if (this._wrapper){
            this._wrapper.classList.remove('playing');
        }
        this.displayPlayBtn = true;
        this.toggleControls();
    }

    get videoId()
    {
        return this.video ? 'video-' + this.video.id : 'video-0';
    }

    get videoIdPlayer()
    {
        return this.video ? 'video-player-' + this.video.id : 'video-player-0';
    }

    toggleControls()
    {
        if (this._video.hasAttribute('controls')) {
            this._video.removeAttribute('controls');
        } else {
            this._video.setAttribute('controls','controls');
        }
    }
}

