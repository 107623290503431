/*
 * Copyright 2023 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2023.02.13 at 16:23
 */

import {forEach, isBool, isBrowser, isEmpty, PlainObject} from '@labor-digital/helferlein';
import {AppContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/AppContext';
import {FrameworkEventList} from '@labor-digital/typo3-vue-framework/lib/Core/Interface/FrameworkEventList';
import {AppStoreKeys} from '../AppStoreKeys';

declare global {
    interface Window {
        dataLayer?: any[],
        gtag?: Function
    }

    interface UcEvent extends Event {
        detail?: any;
    }
}

export class UcService {
    public static init(context: AppContext) {
        if (isBrowser()) {
            window.dataLayer = window.dataLayer || [];
            window.gtag = function () {
                window.dataLayer.push(arguments);
            };

            this.initGoogleConsent();

            // @see https://docs.usercentrics.com/#/v2-events?id=usage-as-window-event
            window.addEventListener('ucEvent', (event: UcEvent) => {
                // console.log('ucEvent', event);
                this.initGoogleAnalytics(event);
                this.initGoogleTagManager(event);
                this.onEvent(context, event);
            });

            // @see https://docs.usercentrics.com/#/v2-events?id=uc_ui_cmp_event
            // window.addEventListener('UC_UI_CMP_EVENT', (event: any) => {
            //     console.log('UC_UI_CMP_EVENT', event);
            //     this.initGoogleAnalytics(event);
            //     this.initGoogleTagManager(event);
            //     this.onEvent(context, event);
            // });
        }

        this.registerCookieManagerLinks(context);
    }

    protected static onEvent(context: AppContext, event: UcEvent): void {
        const detail: PlainObject = Object.keys(event.detail).sort().reduce(function (result, key) {
            result[key] = event.detail[key];
            return result;
        }, {});

        if (isEmpty(detail)) {
            return;
        }

        let action = detail.action;
        delete detail.action;
        delete detail.ucCategory;

        // console.log('onEvent action', action);

        switch (action) {
            case 'onAcceptAllServices':
            case 'onInitialPageLoad': {
                context.store.set(AppStoreKeys.UC_STORE, JSON.stringify(detail));
                break;
            }
            case 'onDenyAllServices':
            case 'onUpdateServices': {
                const ucStore = context.store.get(AppStoreKeys.UC_STORE);
                if (isEmpty(ucStore)) {
                    context.store.set(AppStoreKeys.UC_STORE, JSON.stringify(detail));
                    return;
                }

                const oldUC = JSON.parse(ucStore);
                forEach(detail, (service, key) => {
                    if (oldUC[key] && isBool(oldUC[key]) && service !== oldUC[key]) {
                        location.reload();
                    }
                });
                context.store.set(AppStoreKeys.UC_STORE, JSON.stringify(detail));
            }
        }
    }

    protected static initGoogleConsent() {
        window.gtag('consent', 'default', {
            'ad_storage': 'denied',
            'analytics_storage': 'denied'
        });
    }

    protected static initGoogleAnalytics(event: UcEvent): void {
        if (event.detail['Google Analytics']) {
            window.gtag('consent', 'update', {
                'ad_storage': 'granted',
                'analytics_storage': 'granted'
            });
            return;
        }

        window.gtag('consent', 'update', {
            'ad_storage': 'denied',
            'analytics_storage': 'denied'
        });
    }

    protected static initGoogleTagManager(event: UcEvent): void {
        if (event.detail['Google Tag Manager']) {

            (function (w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({
                    'gtm.start':
                            new Date().getTime(), event: 'gtm.js'
                });
                var f = d.getElementsByTagName(s)[0],
                        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
                // @ts-ignore
                j.async = true;
                // @ts-ignore
                j.src =
                        'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-W9L9SFH');
            return;
        }
    }

    protected static registerCookieManagerLinks(context: AppContext): void {
        const ucClickHandler = (e: MouseEvent) => {
            e.stopImmediatePropagation();
            e.preventDefault();
            e.stopPropagation();
            window.UC_UI.showFirstLayer();
        };
        const ucLinkGenerator = () => {
            setTimeout(() => {
                forEach(document.querySelectorAll('[href*="#cookie-manager"]') as any, (node: HTMLElement) => {
                    // We sadly need this conundrum to remove all event listeners that have been registered
                    // by other components, like the cms link wrap.
                    const parent = node.parentNode as HTMLElement;
                    const clone = node.cloneNode(true);
                    parent.replaceChild(clone, node);
                    clone.addEventListener('click', ucClickHandler);
                });
            }, 500);
        };
        ucLinkGenerator();
        context.eventEmitter.bind(FrameworkEventList.EVENT_ROUTE_AFTER_NAVIGATION, () => ucLinkGenerator());
    }
}
