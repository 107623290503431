








import {PageContext} from "@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext";
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'dx-navbar-brand',
})
export default class NavbarBrand extends Vue
{
    @Prop() readonly context: PageContext;
    @Prop({default: 'default'}) readonly type: string;
};
