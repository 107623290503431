var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dx-navbar overflow-hidden2 bg-white relative z-10",
      class: _vm.classes,
    },
    [
      _c(
        "div",
        { staticClass: "dx-navbar__inner relative py-2 my-2 md:py-4" },
        [
          _c("div", { staticClass: "dx--container-full" }, [
            _c(
              "nav",
              {
                staticClass: "relative flex items-center justify-between",
                attrs: { "aria-label": "Global" },
              },
              [
                _c("div", { staticClass: "flex items-center flex-1" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex items-center justify-between w-full lg:w-auto",
                    },
                    [
                      _c(
                        "router-link",
                        { staticClass: "dx-brand-link", attrs: { to: "/" } },
                        [
                          _c("dx-navbar-brand"),
                          _vm._v(" "),
                          _c("dx-navbar-brand", { attrs: { type: "sm" } }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "flex items-center lg:hidden",
                          staticStyle: { "margin-left": "-20px" },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "p-2 rounded-md inline-flex items-center justify-center focus:bg-blue-200 text-blue focus:text-white focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white",
                              attrs: {
                                "aria-expanded": "false",
                                type: "button",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.openMenu()
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "sr-only" }, [
                                _vm._v("Open main menu"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    height: "22px",
                                    version: "1.1",
                                    viewBox: "0 0 36 22",
                                    width: "36px",
                                    xmlns: "http://www.w3.org/2000/svg",
                                    "xmlns:xlink":
                                      "http://www.w3.org/1999/xlink",
                                  },
                                },
                                [
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        id: "Page-1",
                                        fill: "none",
                                        "fill-rule": "evenodd",
                                        stroke: "none",
                                        "stroke-width": "1",
                                      },
                                    },
                                    [
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            id: "navi",
                                            stroke: "#429BD7",
                                            "stroke-width": "3",
                                            transform:
                                              "translate(-562.000000, -407.000000)",
                                          },
                                        },
                                        [
                                          _c(
                                            "g",
                                            {
                                              attrs: {
                                                id: "Group",
                                                transform:
                                                  "translate(562.089000, 408.707000)",
                                              },
                                            },
                                            [
                                              _c("line", {
                                                attrs: {
                                                  id: "Path",
                                                  x1: "0",
                                                  x2: "35",
                                                  y1: "0.5",
                                                  y2: "0.5",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("line", {
                                                attrs: {
                                                  id: "Path",
                                                  x1: "0",
                                                  x2: "23",
                                                  y1: "9.5",
                                                  y2: "9.5",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("line", {
                                                attrs: {
                                                  id: "Path",
                                                  x1: "0",
                                                  x2: "35",
                                                  y1: "18.5",
                                                  y2: "18.5",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "dx-navbar__menu hidden lg:flex lg:flex:row lg:items-center",
                  },
                  _vm._l(_vm.mainMenu, function (item) {
                    return _c(
                      "div",
                      { key: item.id, staticClass: "flex items-start ml-3" },
                      [
                        _vm._l(item.children, function (child) {
                          return [
                            _c("dx-navbar-item", {
                              key: child.id,
                              attrs: { item: child },
                            }),
                          ]
                        }),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }