var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", {
      staticClass: "dx-navbar-brand h-8 w-auto",
      class: {
        "dx-navbar-brand--sm": _vm.type === "sm",
        "dx-navbar-brand--default": _vm.type === "default",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }