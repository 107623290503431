var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    {
      staticClass: "footer dx-footer bg-white pb-10 md:pb-0",
      class: _vm.classes,
      attrs: { "aria-labelledby": "footer-heading" },
    },
    [
      _c("h2", { staticClass: "sr-only", attrs: { id: "footer-heading" } }, [
        _vm._v("Footer"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "dx--container-wide dx-footer__container py-4 px-4 lg:py-4",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "flex flex-col justify-center md:flex-row md:items-center md:justify-between",
            },
            [
              _c(
                "span",
                { staticClass: "flex justify-center md:flex-none" },
                [
                  _c("dx-icon", {
                    staticClass: "px-5 md:px-0",
                    attrs: { type: "x", size: "xl" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "dx-footer__line w-full mx-7 hidden md:flex",
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-col items-center md:flex-row md:justify-end",
                },
                _vm._l(_vm.footerMenu, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "mt-2 md:mt-0 md:flex md:flex-row",
                    },
                    [
                      item.href
                        ? _c(
                            "span",
                            {
                              class: {
                                "md:border-r md:border-black md:pr-5 md:mr-5":
                                  _vm.isNotLast(index),
                              },
                            },
                            [
                              _c(
                                "dx-text-block",
                                { attrs: { set: "menu" } },
                                [
                                  _c(
                                    "dx-link-wrap",
                                    {
                                      staticClass: "footer__item",
                                      attrs: {
                                        link: item.href,
                                        uppercase: true,
                                        variant: "black",
                                      },
                                    },
                                    [
                                      _c(
                                        "h6",
                                        {
                                          staticClass:
                                            "text-black hover:text-blue-500 transition font-medium",
                                        },
                                        [_vm._v(_vm._s(item.title))]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }