















































import FsnIcon from "../Icon/Icon.vue";
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: "dx-btn",
    components: {FsnIcon}
})
export default class Btn extends Vue
{
    @Prop() text: string;
    @Prop() icon: string;
    @Prop() iconSize: string;
    @Prop() href: string;
    @Prop() to: string;
    @Prop({default: 'primary'}) variant: string;
    @Prop({default: 'default'}) size: string;
    @Prop({default: true}) uppercase: boolean;
    @Prop({default: false}) outline: boolean;
    @Prop({default: true}) rounded: boolean;
    @Prop({default: false}) fullWidth: boolean;
    @Prop({default: false}) disabled: boolean;
    @Prop({default: false}) textLeft: boolean;
    @Prop() target: string;

    get hasIcon()
    {
        return this.icon && this.icon !== undefined;
    }

    get classes()
    {
        if (this.uppercase)
        {
            if(this.textLeft){
                return 'text-uppercase text-left';
            }
            return 'text-uppercase';
        }

        if(this.textLeft){
            return 'text-left';
        }
    }

    get getVariant()
    {
        if (this.outline)
        {
            return 'outline-' + this.variant;
        }
        return this.variant;
    }

    scrollToTarget()
    {
        const isId = this.target.includes('#');
        const target = this.target.replace('#', '').replace('.', '');

        let ele;
        if(target === 'next')
        {
            ele = document.getElementsByTagName('section')[0];
            
        }
        else{
            ele = isId ? document.getElementById(target) : document.getElementsByClassName(target)[0];
        }

        console.log('scrollToTarget ele', target, ele);

        if(ele)
        {
            ele.scrollIntoView({ left: 0, block: 'start', behavior: 'smooth' });
        }
    }
};
