/*
 * Copyright 2020 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2020.11.27 at 10:04
 */

import {forEach, hasPath, isEmpty, PlainObject} from '@labor-digital/helferlein';
import {AppContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/AppContext';
import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import {MenuService} from '../Service/MenuService';
import {IBlogArticle} from '../Component/Features/BlogArticle/BlogArticle.model';

export class JsonLdService
{
    protected static _context: PageContext;

    protected static readonly author: string = 'Dialocx'

    public static initialize(context: AppContext): void
    {
        this._context = context.pageContext;
    }

    public static makeOrganization(): PlainObject
    {
        return {
            vmid: 'jsonLdOrganization',
            type: 'application/ld+json',
            json: {
                '@context': 'https://schema.org',
                '@type': 'Organization',
                name: this.author,
                legalName: this.author,
                url: this._context.siteUrl,
                // logo: 'https://dialocx.de/fileadmin/user_upload/brand/logo.png'
            }
        };
    }

    public static makeBreadcrumb(altTitle?: string): PlainObject
    {
        const items = [];
        const baseUrl = this.baseUrl;
        let position = 1;
        let lastItem = null;

        forEach(MenuService.rootLine, item => {
            lastItem = {
                '@type': 'ListItem',
                position: position++,
                name: item.title,
                item: baseUrl + item.slug
            };
            items.push(lastItem);
        });

        // Update the last child
        if (lastItem !== null) {
            lastItem.name = altTitle ?? lastItem.name;
            lastItem.item = baseUrl + this._context.router.currentRoute.path;
        }

        const data = {
            vmid: 'jsonLdBreadcrumb',
            type: 'application/ld+json',
            json: {
                '@context': 'https://schema.org',
                '@type': 'BreadcrumbList',
                itemListElement: items
            }
        };

        // console.log('[JsonLdService] makeBreadcrumb', data);

        return data;
    }

    public static makeBlogArticle(article: IBlogArticle, title: string, description: string, link?: string, imageUrl?: string): PlainObject
    {
        const image = isEmpty(imageUrl) ? {} : {
            image: {
                '@type': 'ImageObject',
                'url': imageUrl
            }
        };

        const data = {
            vmid: 'jsonLdMagazine',
            type: 'application/ld+json',
            json: {
                '@context': 'https://schema.org',
                '@type': 'BlogPosting',
                mainEntityOfPage: {
                    '@type': 'WebPage',
                    '@id': link ?? this.baseUrl + this._context.router.currentRoute.path
                },
                headline: title,
                description: description,
                ...image,
                author: article.authorName ? article.authorName : (article.author ? article.author.name : this.author),
                publisher: this.makeOrganization().json
            }
        };

        // console.log('[JsonLdService] makeBlogArticle', data);

        return data;
    }

    public static makeNews(news: any, title: string, description: string, link?: string, imageUrl?: string): PlainObject
    {
        const image = isEmpty(imageUrl) ? {} : {
            image: {
                '@type': 'ImageObject',
                'url': imageUrl
            }
        };

        const data = {
            vmid: 'jsonLdMagazine',
            type: 'application/ld+json',
            json: {
                '@context': 'https://schema.org',
                '@type': 'NewsArticle',
                mainEntityOfPage: {
                    '@type': 'WebPage',
                    '@id': link ?? this.baseUrl + this._context.router.currentRoute.path
                },
                headline: title,
                description: description,
                ...image,
                author: news.author ? (news.author ? news.author.name : this.author) : 'Dialocx',
                publisher: this.makeOrganization().json
            }
        };

        return data;
    }


    protected static get baseUrl(): string
    {
        return this._context ? this._context.baseUrl.replace(/\/$/, '') : '';
    }
}
