var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dx-drop mx-auto",
      class: {
        "relative max-w-md md:max-w-2xl lg:max-w-5xl xl:max-w-7xl":
          _vm.relative,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dx-drop__title z-50", class: _vm.classesDrop },
        [
          _vm.text || (_vm.links && _vm.links.length)
            ? _c("div", { staticClass: "dx-drop__title-inner" }, [
                _c("div", {
                  staticClass: "dx-drop__title-value pt-3 hyphens",
                  class: _vm.classesTitle,
                  domProps: { innerHTML: _vm._s(_vm.text) },
                }),
                _vm._v(" "),
                _vm.links && _vm.links.length
                  ? _c(
                      "div",
                      _vm._l(_vm.links, function (item, index) {
                        return _c(
                          "dx-link-wrap",
                          {
                            key: index,
                            staticClass: "mt-3 hyphens",
                            attrs: { variant: "white", link: item.link },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  " flex flex-row items-center justify-start",
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(item.label) +
                                    "\n                    "
                                ),
                                _c("dx-icon", {
                                  staticClass: "ml-3 w-10",
                                  attrs: {
                                    type: "arrow-right-white",
                                    size: "xs",
                                    color: "white",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }