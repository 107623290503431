















import {AppContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/AppContext';
import DxLoaderSpinner from "../LoaderSpinner/LoaderSpinner.vue";

export default {
    name: 'dx-loader-overlay',
    components: {DxLoaderSpinner},
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        classes() {
            const context: AppContext = this.$root.appContext;
            return {
                ['dx-loader-overlay--' + context.store.get('colorSchema')]: true
            };
        }
    }
};
