




















import DxLinkWrap from "../LinkWrap/LinkWrap.vue";
import { Component, Prop, Vue } from 'vue-property-decorator';
import DxIcon from '../Icon/Icon.vue';

// @ts-ignore
@Component({
    name: 'dx-drop',
    components: {DxLinkWrap, DxIcon}
})
export default class Drop extends Vue
{
    @Prop() readonly text: string;
    @Prop() readonly type: string;
    @Prop() readonly links: any;
    @Prop({default: false}) readonly hiddenSm: boolean;
    @Prop({default: true}) readonly relative: boolean;

    get classesTitle()
    {
        switch (this.type)
        {
            case 'drop_1':
            case 'drop_2':
            case 'drop_3':
            case 'drop_4':
            case 'drop_5':
                return 'text-xl  lg:text-3xl xl:text-4xl font-bold'
        }

        return 'text-3xl font-extrabold';
    }

    get classesDrop()
    {
        return 'dx-drop__title--' + this.type + (this.hiddenSm ? 'hidden md:block' : '');
    }
};
