import {isEmpty} from '@labor-digital/helferlein';
import {Component, Vue} from 'vue-property-decorator';

@Component
// @ts-ignore
export default class TextMixin extends Vue
{
    stripTags(text?): string
    {
        return text ? text.replace(/(<([^>]+)>)/ig, '') : text;
    };

    truncate(text, length): string
    {
        if (isEmpty(text)) {
            return '';
        }
        return text.substr(0, length);
    };
}
