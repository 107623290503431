






















import { PageContext } from "@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext";
import DxBtn from "../../Component/Common/Btn/Btn.vue";
import DxMainMenu from "../../Component/Common/MainMenu/MainMenu";
import DxFooter from "../../Component/Common/Footer/Footer";
import DxAppContainer from "../../Component/Common/AppContainer/AppContainer";
import DxNavbar from "../../Component/Common/Navbar/Navbar.vue";
import DxHero from "../../Component/Common/Hero/Hero.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import DxModal from "../../Component/Common/Modal/Modal.vue";
import * as _ from "lodash";
import { AppStoreKeys } from "src/AppStoreKeys";
import DxLinkWrap from "../../Component/Common/LinkWrap/LinkWrap.vue";
import DxImage from "../../Component/Common/Assets/Image/Image.vue";
import DxSidebar from "../../Component/Common/Sidebar/Sidebar.vue";

@Component({
    name: "DefaultLayout",
    components: {
        DxImage,
        DxLinkWrap,
        DxModal,
        DxMainMenu,
        DxNavbar,
        DxAppContainer,
        DxBtn,
        DxFooter,
        DxHero,
        DxSidebar,
    },
})
export default class DefaultLayout extends Vue {
    @Prop() readonly context: PageContext;

    get transitionName() {
        return "animate__animated animate__fast animate__" + this.context.store.get(AppStoreKeys.PAGE_TRANSITION_NAME, false);
    }

    get level(): number {
        return _.toNumber(this.context.rootLine.length);
    }

    get sidebarLinks() {
        return this.context.commonElements.sidebarLinks;
    }

    get isContactPage(): boolean {
        return this.context.rootLine.length > 1 && this.context.rootLine[1].id === 38;
    }

    get hero() {
        return this.context.data.get("hero");
    }
}
