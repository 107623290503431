
























































import { stopBodyScrolling } from "@labor-digital/helferlein";
import { PageContext } from "@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext";
import DxLinkWrap from "../LinkWrap/LinkWrap.vue";
import DxIcon from "../Icon/Icon";
import DxTextBlock from "../TextBlock/TextBlock";
import DxNavbarBrand from "../Navbar/NavbarBrand/NavbarBrand";
import DxNavbarItem from "../Navbar/NavbarItem/NavbarItem";
import DxMainMenu from "../MainMenu/MainMenu.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { AppStoreKeys } from "./../../../AppStoreKeys";
import { BreakpointService } from "./../../../Service/Breakpoint.service";

// @ts-ignore
@Component({
    name: "dx-navbar",
    components: { DxLinkWrap, DxMainMenu, DxTextBlock, DxIcon, DxNavbarBrand, DxNavbarItem },
})
export default class Navbar extends Vue {
    @Prop() readonly context: PageContext;
    @Prop({ default: true }) sticky: boolean;
    @Prop({ default: false }) showContactBtn: boolean;
    @Prop({ default: false }) hasLargeHero: boolean;

    get mainMenu() {
        this.$route.fullPath;
        // console.log('this.context.commonElements.mainMenu ', this.context.commonElements.mainMenu);
        return this.context.commonElements.mainMenu;
    }

    get contactLink(): string {
        return this.context ? this.context.linkRepository.get("contact") : "";
    }

    get classes() {
        // const isHome = _.toNumber(this.context.rootLine.length) === 1;
        this.context.store.get(AppStoreKeys.BREAKPOINT, null);

        let th = this.hasLargeHero ? 840 : 600;
        if (BreakpointService.isMobile()) {
            th = 200;
        }
        const scrollPos = this.context.store.get(AppStoreKeys.SCROLL_POS, 0);
        return {
            ["sticky-top shadow-lg"]: scrollPos >= th,
            ["dx-navbar__scrolling "]: scrollPos > 100,
        };
    }

    openMenu() {
        this.context.store.set(AppStoreKeys.MENU_TOGGLED, true);
        stopBodyScrolling(true);
    }
}
