



































































import { PageContext } from "@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext";
import DxTextBlock from "../TextBlock/TextBlock";
import DxMainMenuItem from "../MainMenu/MainMenuItem/MainMenuItem";
import DxIcon from "../Icon/Icon.vue";
import DxBtn from "../Btn/Btn.vue";
import DxLinkWrap from "src/Component/Common/LinkWrap/LinkWrap.vue";
import { Component, Emit, Prop, VModel, Vue } from "vue-property-decorator";
import { AppStoreKeys } from "src/AppStoreKeys";
import { isBrowser, stopBodyScrolling } from "@labor-digital/helferlein";
import DxNavbarItem from "../Navbar/NavbarItem/NavbarItem.vue";

// @ts-ignore
@Component({
    name: "dx-main-menu",
    components: {
        DxNavbarItem,
        DxLinkWrap,
        DxBtn,
        DxIcon,
        DxTextBlock,
        DxMainMenuItem,
    },
})
export default class MainMenu extends Vue {
    @Prop() readonly context: PageContext;

    mounted() {
        // - menu can be closed by any click - as long as it is not the menu button
        window.addEventListener("click", (event) => {
            if (this.isOpen === true) {
                if (event.target && event.target.outerText && event.target.outerText.toLowerCase() !== "menu") {
                    this.close();
                }
            }
        });
    }

    get isOpen() {
        return this.context.store.get(AppStoreKeys.MENU_TOGGLED, false);
    }

    close() {
        this.context.store.set(AppStoreKeys.MENU_TOGGLED, false);
        stopBodyScrolling(false);
    }

    get mainMenu() {
        this.$route.fullPath;
        return this.context.commonElements.mainMenu;
    }

    get socialLinks() {
        return this.context.commonElements.socialLinks;
    }
}
