import Vue from 'vue';
import {isElementInViewport} from '@labor-digital/helferlein';

const ScrollAnimate = () => {

    const isDirectionAgnostic = (params) => typeof params === 'string'

    const isBiDirectional = (params) => params.down && params.up

    const hasBeenApplied = (current = '', prev = '') => current.trim() !== prev.trim()

    const applySkipClass = (el) => el.classList.add('animate__skip')

    const shouldSkip = (el) => el.className.includes('animate__skip')

    const shouldResetAnimation = ({params, isUpwards, repeat}) => repeat &&
        (isUpwards && params.down || !isUpwards && params.up)

    const applyAnimationClass = (el, current, newClass = '') => el.className = `${current} ${newClass}`.trim()

    return {
        applySkipClass: applySkipClass,
        isElementInViewport: isElementInViewport,
        run(el, {value: params, modifiers}, {isUpwards, previousClassName = ''}, scrollTop: number)
        {
            const offsetTop = params.offset ? params.offset : 10;

            if(!this.isElementInViewport(el, offsetTop)) {
                if (modifiers.repeat && isDirectionAgnostic(params)) {
                    return applyAnimationClass(el, previousClassName);
                }
                return;
            }

            // if the element is already fully visible, do not animate
            if(this.isElementInViewport(el, offsetTop) && scrollTop === 0 || this.isElementInViewport(el, {offsetTop: offsetTop, onlyFull: true})) {
                applySkipClass(el);
                return;
            }

            if (shouldSkip(el)){
                return;
            }

            if (isDirectionAgnostic(params)) {
                return applyAnimationClass(el, previousClassName, params);
            }

            if (modifiers.repeat ||
                isBiDirectional(params) ||
                !hasBeenApplied(el.className, previousClassName)) {
                const animationClass = isUpwards ? params.up : params.down;
                return applyAnimationClass(el, previousClassName, animationClass);
            }

            if(shouldResetAnimation({params, isUpwards, ...modifiers})) {
                return applyAnimationClass(el, previousClassName);
            }
        }
    }
}

/**
 * Applies animate classes on scrolling. Uses animate.css
 *
 * Options:
 * - only animation classes as string
 * - object: down, up, repeat, offset
 *
 * <div v-animate-onscroll="'animate__animated animate__slideUpAndAppear animate__fast'"></div>
 * <div v-animate-onscroll="{down: 'animate__animated animate__slideUpAndAppear animate__fast'}"></div>
 * <div v-animate-onscroll="{up: 'animate__animated animate__slideUpAndAppear animate__fast'}"></div>
 * <div v-animate-onscroll="{down: 'animate__animated animate__slideUpAndAppear animate__fast', offset: 300}"></div>
 */
const AnimateOnScroll = {
    inserted(el, binding) {
        const scrollAnimate = ScrollAnimate();
        const previousClassName = el.className;
        let lastScrollTop = window.pageYOffset;

        // - if the element is already in viewport, it should be skipped
        if(scrollAnimate.isElementInViewport(el)) {
            scrollAnimate.applySkipClass(el);
        }

        window.addEventListener('scroll', function() {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const isUpwards = scrollTop < lastScrollTop;
            scrollAnimate.run(el, binding, {isUpwards, previousClassName}, scrollTop);
            lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
        }, false)
    }
};

export default AnimateOnScroll;
Vue.directive('animate-onscroll', AnimateOnScroll);
