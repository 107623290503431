

























import { Component, Prop, Vue } from "vue-property-decorator";
import { PageContext } from "@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext";
import DxBtn from "../Btn/Btn.vue";
import { IImage } from "../Assets/Image.model";
import { IVideo } from "../Assets/Video.model";
import DxLinkWrap from "../LinkWrap/LinkWrap.vue";
import DxDrop from "../Drop/Drop.vue";
import DxImage from "../Assets/Image/Image.vue";
import DxVideoPlayer from "../Assets/VideoPlayer/VideoPlayer.vue";
import { BreakpointService } from "../../../Service/Breakpoint.service";
import { AppStoreKeys } from "src/AppStoreKeys";

@Component({
    name: "dx-hero",
    components: { DxVideoPlayer, DxLinkWrap, DxBtn, DxImage, DxDrop },
    mounted() {
        // console.log('hero', this.hero);
    },
})
export default class Hero extends Vue {
    @Prop() readonly context: PageContext;
    @Prop() hero: {
        title?: string;
        dropTitle?: string;
        displaySmallTitle?: boolean;
        isLarge: boolean;
        useVideo: boolean;
        links: any;
        image?: IImage;
        imageSm?: IImage;
        video?: IVideo;
    };

    get useVideo() {
        // - listen to breakpoint changes
        this.context.store.get(AppStoreKeys.BREAKPOINT, null);

        return !BreakpointService.isSm();
    }

    get hasImage() {
        return this.hero.image || this.hero.imageSm;
    }
}
