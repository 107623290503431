import { render, staticRenderFns } from "./Hero.vue?vue&type=template&id=41a72e14&"
import script from "./Hero.vue?vue&type=script&lang=ts&"
export * from "./Hero.vue?vue&type=script&lang=ts&"
import style0 from "Hero.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('41a72e14')) {
      api.createRecord('41a72e14', component.options)
    } else {
      api.reload('41a72e14', component.options)
    }
    module.hot.accept("./Hero.vue?vue&type=template&id=41a72e14&", function () {
      api.rerender('41a72e14', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Component/Common/Hero/Hero.vue"
export default component.exports