// import { Subject } from 'rxjs';
// const subject = new Subject();

import {isBrowser} from "@labor-digital/helferlein";

const BreakpointService = {

    isSm()
    {
        return isBrowser() ? window.innerWidth < 768 : false
    },
    isMd()
    {
        return isBrowser() ? window.innerWidth >= 768 && window.innerWidth < 993 : false
    },
    isLg()
    {
        return isBrowser() ? window.innerWidth >= 993 && window.innerWidth < 1200 : false
    },
    isXlg()
    {
        return isBrowser() ? window.innerWidth >= 1200 : false
    },
    isMobile()
    {
        if(!isBrowser()){
            return false;
        }

        return this.isSm(window) || this.isMd(window)
    }
}

export { BreakpointService }
