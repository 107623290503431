


import {forEach} from "@labor-digital/helferlein";
import {isUndefined} from "@labor-digital/helferlein";
import {CreateElement, VNode} from "vue";

const sets = {
  menu: {
    font: "rc",
    size: 20,
    uppercase: true
  },
  body01: {
    font: "g",
    size: 28,
    allowResize: true,
    cmsBodyAddons: true
  },
  body02: {
    font: "g",
    size: 22,
    allowResize: true,
    cmsBodyAddons: true
  },
  headline: {
    font: "headline",
    size: 0,
    allowResize: true,
    cmsBodyAddons: true
  }
};
export default {
  name: 'dx-text-block',
  props: {
    tag: {
      type: String,
      default: "span"
    },
    set: {
      type: String,
      default: "",
      validator: v => v === "" || !isUndefined(sets[v])
    },
    font: {
      type: String,
      default: "",
      validator: v => [
        "", "r", "roboto", "rc", "robotoCondensed", "g", "garamond"
      ].indexOf(v) !== -1
    },
    size: {
      type: [Number, String],
      default: 0
    },
    allowResize: {
      type: Boolean,
      default: false
    },
    cmsBodyAddons: {
      type: Boolean,
      default: false
    },
    uppercase: {
      type: Boolean,
      default: false
    },
    bold: {
      type: Boolean,
      default: false
    }
  },
  render(createElement: CreateElement): VNode | VNode[] {
    // Load the given definition
    const definition = {
      font: this.font,
      size: this.size,
      allowResize: this.allowResize,
      cmsBodyAddons: this.cmsBodyAddons,
      uppercase: this.uppercase,
      bold: this.bold
    };
    // Apply set if given
    if (this.set !== "") {
      if (!isUndefined(sets[this.set])) {
        forEach(sets[this.set], (v, k) => {
          definition[k] = v;
        });
      }
    }
    // Rewrite font shortcuts
    if (definition.font === "") definition.font = "rc";
    if (definition.font === "rc") definition.font = "robotoCondensed";
    if (definition.font === "r") definition.font = "roboto";
    if (definition.font === "g") definition.font = "garamond";

    // Build class definition
    const classes = [];
    classes.push("dx-text-block--" + definition.font);
    classes.push("dx-text-block--" + this.tag);
    if (definition.size > 0) classes.push("dx-text-block--size" + definition.size);
    if (definition.allowResize) classes.push("dx-text-block--allowResize");
    if (definition.cmsBodyAddons) classes.push("dx-text-block--cmsBodyAddons");
    if (definition.uppercase) classes.push("dx-text-block--upper");
    if (definition.bold) classes.push("dx-text-block--bold");

    return createElement(this.tag, {
      staticClass: "dx-text-block",
      class: classes.join(" ")
    }, this.$slots.default);
  }
};
