/*
 * Copyright 2020 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2020.04.14 at 16:08
 */

import {PlainObject} from '@labor-digital/helferlein';
import {forEach} from '@labor-digital/helferlein';
import {getPath} from '@labor-digital/helferlein';
import {AppContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/AppContext';
import {RootLineElementInterface} from '@labor-digital/typo3-vue-framework/lib/Core/Interface/RootLineElementInterface';
import {PidRepository} from '@labor-digital/typo3-vue-framework/lib/Core/Module/Spa/PidRepository';

export class MenuService
{

    /**
     * The main context to resolve the requirements with
     */
    protected static _context: AppContext;

    /**
     * Initializes the service by providing the app context
     * @param context
     */
    public static initialize(context: AppContext): void
    {
        this._context = context;
    }

    /**
     * Lookup for the root line data
     */
    public static get rootLine(): Array<RootLineElementInterface>
    {
        // @ts-ignore
        return getPath(this._context, 'pageContext.rootLine', []);
    }

    /**
     * Lookup for the main menu
     */
    public static get mainMenu(): Array<PlainObject>
    {
        // @ts-ignore
        return getPath(this._context, 'pageContext.commonElements.mainMenu', []);
    }

    // /**
    //  * Returns the link object for the current page or null
    //  * if we are to early in the lifecycle to determine the page correctly
    //  */
    // public static get currentPage(): MainMenuLink | null
    // {
    //     const rootLine = this.rootLine;
    //
    //     // Ignore early requests
    //     if (isEmpty(rootLine)) {
    //         return null;
    //     }
    //
    //     // Special handling on a home page
    //     const homePid = this.getHomePid();
    //     let currentPage: MainMenuLink = null;
    //     if (parseInt(rootLine[rootLine.length - 1].id + '') === this.getHomePid()) {
    //         const links = this.getMasterToggleLinks();
    //         forEach(links, (link: MasterToggleLink) => {
    //             if (link.id !== homePid) {
    //                 return;
    //             }
    //             currentPage = link;
    //             return false;
    //         });
    //         return currentPage;
    //     }
    //
    //     // Find the current page in the tree
    //     let mainMenuLayer = this.mainMenu;
    //     const depth = rootLine.length;
    //     let layer = 1;
    //     forEach(rootLine, (page: RootLineElementInterface) => {
    //         forEach(mainMenuLayer, (item: MainMenuLink) => {
    //             if (item.id === parseInt(page.id + '')) {
    //
    //                 // Check if this is the last rootline element
    //                 if (layer === depth) {
    //                     currentPage = item;
    //                     return false;
    //                 }
    //
    //                 // Ignore empty children
    //                 if (isEmpty(item.children)) {
    //                     return false;
    //                 }
    //
    //                 // Go down further
    //                 mainMenuLayer = item.children;
    //                 return false;
    //             }
    //         });
    //
    //         // Note: There might be a start page (pid: 1) in our root line
    //         // we skip it silently while gathering the current page
    //         layer++;
    //
    //         // Break if we found the page
    //         if (currentPage !== null) {
    //             return false;
    //         }
    //     });
    //
    //     return currentPage;
    // }

    /**
     * Checks if the given PID exists in the root line
     * @param pid
     */
    public static isIdInRootLine(pid: number): boolean
    {
        let found = false;
        forEach(this.rootLine, (el: RootLineElementInterface) => {
            if (el.id != pid) {
                return;
            }
            found = true;
            return false;
        });
        return found;
    }

    /**
     * Internal access helper to the pid repository
     */
    protected static get pidRepository(): PidRepository
    {
        return this._context.pageContext.pidRepository;
    }
}
