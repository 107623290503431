var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dx-loader-overlay", class: _vm.classes },
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm.show
        ? _c(
            "div",
            {
              staticClass:
                "dx-loader-overlay__overlay animate__animated animate__fadeIn animate__faster",
            },
            [
              _c("div", { staticClass: "dx-loader-overlay__overlayInner" }, [
                _c(
                  "div",
                  { staticClass: "dx-loader-overlay__loader" },
                  [_c("dx-loader-spinner")],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }