







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'dx-icon'
})
export default class Icon extends Vue
{
    @Prop() type: string;
    @Prop() size: string;
    @Prop() color: string;

    get classes()
    {
        const c = {};
        c['dx-icon--' + this.type] = true;
        if (this.size)
        {
            c['dx-icon__' + this.size] = true;
        }
        if (this.color)
        {
            c['dx-icon__' + this.color] = true;
        }
        return c;
    }
};
